<template>
  <v-container>
    <v-row v-if="instance" class="text-center pt-5">
      <v-col cols="12" v-if="instance.isInstanceUp">
        <v-row>
          <v-col cols="12">
            <v-btn @click="$router.replace('/setup')" class="black white--text ml-3 mt-3" text outlined>START SETUP
            </v-btn>
            <v-btn @click="$router.replace('/history')" class="black white--text ml-3 mt-3" text outlined>View History
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="d-flex">
            <v-text-field @keyup.enter="sendMessage" v-model="message" dense outlined
                          label="Type text message here..."></v-text-field>
            <v-btn color="blue" class="ml-1" rounded icon @click="sendMessage">
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="auto" v-if="instanceStatus">
            <template v-if="instanceStatus.instanceSession.wa_user">
              <div>User Name - {{ instanceStatus.instanceSession.wa_user.user.name }}</div>
              <div>Device - {{ instanceStatus.instanceSession.wa_user.user.phone.device_model }}</div>
            </template>
            <template v-if="instanceStatus.instanceSession.wa_status">
              <div>Battery Level - {{ instanceStatus.instanceSession.wa_status.battery.value }}</div>
            </template>
            <div>Mobile - {{ instanceStatus.instanceSession.mobile }}</div>
            <div>Phone Connected - {{ instanceStatus.connection.phoneConnected }}</div>
            <div>State - {{ instanceStatus.connection.state }}</div>
            <div>Last Seen - {{ instanceStatus.connection.lastSeen }}</div>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
      <v-col v-else cols="12">
        <strong>Instance is down. Start for further actions.</strong>
      </v-col>
    </v-row>
    <v-row v-else class="text-center pt-5">
      <v-col cols="12">
        <strong>Select Instance first to go further.</strong></v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { EventBus } from '@/plugins/EventBus'

export default {
  name: 'Home',
  props: {
    url: { type: [String], default: null },
  },
  data () {
    return {
      message: '', instanceStatus: {}, instance: null,
    }
  },
  mounted () {
    this.instance = this.$store.getters.instance
    EventBus.$on('instanceUpdate', instance => {
      this.instance = instance
      this.$forceUpdate()
    })
    this.instanceStatus = this.$store.getters.instanceStatus
    EventBus.$on('instanceStatusUpdate', instance => {
      this.instanceStatus = instance
      this.$forceUpdate()
    })
  },
  methods: {
    sendMessage () {
      axios.post(`${this.url}sendMessage`, { to: '918980581991', messageType: 'TEXT', content: this.message }).
          catch(err => {console.log(err)})
    },
  },
}
</script>
